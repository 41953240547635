import type { RouteMap } from "#vue-router"
import type { Provider } from "~/server/routes/admin/v1/workspace"
type RouteName<Name extends keyof RouteMap = keyof RouteMap> = Name
export type OnboardingStep = {
  link: RouteName
  title: string
  hidden?: boolean
}

export function useOnboarding() {
  const completedSteps = useCookie<string[]>("completed_steps", {
    default: () => [],
  })

  const onboardingProvider = useCookie<Provider | undefined>(
    "onboarding_provider",
  )

  /** Used to save training result after answering quiz */
  const cachedTrainingResult = useState<TrainingSettings | undefined>(
    () => undefined,
  )

  const steps = computed(
    () =>
      [
        { link: "onboarding", title: "Welcome" },
        { link: "onboarding-risk-level", title: "Set risk level" },
        { link: "onboarding-integration", title: "Setup provider" },
        {
          link:
            onboardingProvider.value == "google"
              ? "onboarding-setup-google"
              : "onboarding-setup-microsoft",
          title: "Whitelisting",
          hidden: !["google", "microsoft"].includes(
            onboardingProvider.value ?? "",
          ),
        },
        { link: "onboarding-quiz", title: "Quiz" },
        {
          link: "onboarding-finished",
          title: "Onboarding finished",
          hidden: true,
        },
      ] satisfies OnboardingStep[],
  )
  const route = useRoute()
  function completeStep() {
    if (!completedSteps.value.includes(route.name)) {
      completedSteps.value = [...completedSteps.value, route.name]
    }
  }

  function isStepCompleted(step: OnboardingStep) {
    return completedSteps.value.includes(step.link)
  }

  const currentStep = computed(() => {
    return steps.value.find((step) => {
      return route.name.includes(step.link)
    })
  })
  return {
    completedSteps,
    steps,
    currentStep,
    completeStep,
    cachedTrainingResult,
    isStepCompleted,
    onboardingProvider,
  }
}
